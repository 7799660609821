export const sizes = {
  mobile: `320px`,
  mobileL: `400px`,
  mobileXL: `586px`,
  tablet: `768px`,
  laptop: `1024px`,
  desktop: `1440px`,
  desktopFHD: `1920px`,
};

export const minWidth = {
  mobile: `min-width: ${sizes.mobile}`,
  mobileL: `min-width: ${sizes.mobileL}`,
  mobileXL: `min-width: ${sizes.mobileXL}`,
  tablet: `min-width: ${sizes.tablet}`,
  laptop: `min-width: ${sizes.laptop}`,
  desktop: `min-width: ${sizes.desktop}`,
  desktopFHD: `min-width: ${sizes.desktopFHD}`,
};

export const maxWidth = {
  mobile: `max-width: ${sizes.mobile}`,
  mobileL: `max-width: ${sizes.mobileL}`,
  mobileXL: `max-width: ${sizes.mobileXL}`,
  tablet: `max-width: ${sizes.tablet}`,
  laptop: `max-width: ${sizes.laptop}`,
  desktop: `max-width: ${sizes.desktop}`,
  desktopFHD: `max-width: ${sizes.desktopFHD}`,
};

export const minHeight = {
  mobile: `min-height: ${sizes.mobile}`,
  mobileL: `min-height: ${sizes.mobileL}`,
  mobileXL: `min-height: ${sizes.mobileXL}`,
  tablet: `min-height: ${sizes.tablet}`,
  laptop: `min-height: ${sizes.laptop}`,
};

export const maxHeight = {
  mobile: `max-height: ${sizes.mobile}`,
  mobileL: `max-height: ${sizes.mobileL}`,
  mobileXL: `max-height: ${sizes.mobileXL}`,
  tablet: `max-height: ${sizes.tablet}`,
  laptop: `max-height: ${sizes.laptop}`,
};

export const portraitOnly = `orientation: portrait`;
export const landscapeOnly = `orientation: landscape`;

export const portrait = {
  small: `(${portraitOnly}) and (${minWidth.mobile})`,
  medium: `(${portraitOnly}) and (${minWidth.mobileL})`,
  large: `(${portraitOnly}) and (${minWidth.tablet})`,
  xl: `(${portraitOnly}) and (${minWidth.laptop})`,
};

export const landscape = {
  small: `(${landscapeOnly}) and (${minHeight.mobile})`,
  medium: `(${landscapeOnly}) and (${minHeight.mobileL})`,
  large: `(${landscapeOnly}) and (${minHeight.tablet})`,
  xl: `(${landscapeOnly}) and (${minHeight.laptop})`,
};
