import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/layouts/Layout';
import { fg, whiteAlpha2 } from '../styles/color';
import { minWidth, sizes } from '../styles/mq';

const Main = styled.main`
  @media (${minWidth.mobileL}) {
    margin: 0 auto;
    max-width: ${sizes.mobileL};
  }
  @media (${minWidth.mobileXL}) {
    max-width: ${sizes.mobileXL};
  }
  @media (${minWidth.laptop}) {
    max-width: ${sizes.tablet};
  }
  @media (${minWidth.desktop}) {
    max-width: ${sizes.laptop};
  }
`;

const Description = styled.p`
  display: block;
  text-align: center;
`;

const List = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 1rem auto;
  max-width: 400px;
`;

const ExternalLink = styled.a`
  align-items: center;
  border-radius: 1rem;
  color: ${fg};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  overflow: hidden;
  padding: 1rem;
  text-decoration: none;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${whiteAlpha2};
  }
`;

const Icon = styled.img`
  display: inline-block;
  min-height: 100px;
  min-width: 100px;
  transition: all 0.2s ease-in-out;

  @media (${minWidth.laptop}) {
    max-width: ${sizes.tablet};
  }
`;

const Label = styled.span`
  display: block;
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: center;
`;

export default () => {
  const {
    site: {
      siteMetadata: { portfolio },
    },
  } = useStaticQuery(graphql`
    query PortfolioQuery {
      site {
        siteMetadata {
          portfolio {
            label
            href
            icon
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO title="Portfolio" />
      <Layout>
        <Main>
          <Description>Examples of my recent work are available on CodePen and GitHub.</Description>
          <List>
            {portfolio.map(({ label, href, icon }) => (
              <ExternalLink key={label} href={href} target="_blank" rel="noopener noreferrer">
                <Icon src={icon} alt={label} />
                <Label>{label}</Label>
              </ExternalLink>
            ))}
          </List>
        </Main>
      </Layout>
    </>
  );
};
