import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { sizes, minWidth, portraitOnly, landscapeOnly, minHeight } from '../../styles/mq';
import { fg, whiteAlpha1, whiteAlpha2, textShadow, bgHeader } from '../../styles/color';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

const Header = styled.header`
  background-color: ${bgHeader};
  position: sticky;
  top: 0px;
  z-index: 10;
`;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 1rem;

  @media (${minWidth.mobileXL}) {
    margin: 0 auto;
    max-width: ${sizes.mobileXL};
  }
  @media (${minWidth.tablet}) {
    max-width: ${sizes.tablet};
  }
  @media (${minWidth.desktop}) {
    max-width: ${sizes.laptop};
  }
`;

const Title = styled.h1`
  margin: 0.25em 0;
  text-align: left;

  @media (${portraitOnly}) {
    font-size: calc(24px + 2vw);
  }

  @media (${landscapeOnly}) {
    font-size: calc(24px + 2vh);
  }
`;

const TitleLink = styled(Link)`
  color: ${fg};
  text-decoration: none;
`;

const Nav = styled.nav`
  margin: 0;
`;

const NavLink = styled(Link)`
  align-items: center;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: 4rem;
  text-decoration: none;

  @media (${portraitOnly}) and (${minWidth.tablet}), (${landscapeOnly}) and (${minHeight.mobile}) {
    flex-flow: row nowrap;
    padding: 0.5rem;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: ${whiteAlpha2};
    }
  }

  &.activeStyle {
    background-color: ${whiteAlpha1};

    &:hover {
      background-color: ${whiteAlpha2};
    }
  }
`;

const Icon = styled.img`
  color: #fff;
  display: block;
  padding: 0.5rem;

  @media (${portraitOnly}) {
    width: calc(1rem + 2vh);
    height: calc(1rem + 2vh);
  }
  @media (${landscapeOnly}) {
    width: calc(1rem + 1.75vh);
    height: calc(1rem + 1.75vh);
  }
`;

const Label = styled.span`
  color: ${fg};
  display: block;
  font-size: 0.875em;
  padding: 0 0.5rem 0.5rem;
  text-shadow: ${textShadow};
  transition: all 0.2s ease-out;

  @media (${portraitOnly}) and (${minWidth.tablet}), (${landscapeOnly}) and (${minHeight.mobile}) {
    font-size: calc(1rem + 1.75vh);
    padding: 0.25em 0.35em;
  }

  @media (${portraitOnly}) and (${minWidth.tablet}) {
    font-size: calc(1rem + 1.75vh);
    padding: 0.25em 0.35em;
  }
`;

const Layout = ({ children }) => (
  <Container>
    <Header>
      <HeaderWrapper>
        <Title>
          <TitleLink to="/">MikeDPad</TitleLink>
        </Title>
        <Nav>
          <NavLink to="/log/" partiallyActive activeClassName="activeStyle">
            <Icon
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE4IDJINmEyIDIgMCAwMC0yIDJ2MTZjMCAxLjEuOSAyIDIgMmgxMmEyIDIgMCAwMDItMlY0YTIgMiAwIDAwLTItMnpNOSA0aDJ2NWwtMS0uOEw5IDlWNHptOSAxNkg2VjRoMXY5bDMtMi4zIDMgMi4zVjRoNXYxNnoiLz48L3N2Zz4="
              alt="Log"
            />
            <Label>Log</Label>
          </NavLink>
          <NavLink to="/portfolio/" partiallyActive activeClassName="activeStyle">
            <Icon
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkuMiA2bDIgMkgyMHYxMEg0VjZoNS4ybS44LTJINGEyIDIgMCAwMC0yIDJ2MTJjMCAxLjEuOSAyIDIgMmgxNmEyIDIgMCAwMDItMlY4YTIgMiAwIDAwLTItMmgtOGwtMi0yeiIvPjwvc3ZnPg=="
              alt="Portfolio"
            />
            <Label>Portfolio</Label>
          </NavLink>
        </Nav>
      </HeaderWrapper>
    </Header>
    {children}
  </Container>
);

export default Layout;
